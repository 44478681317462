<script>
  import { onMount } from "svelte";
  // library that creates chart objects in page
  import Chart from "chart.js";
  export let title;
  export let chart;
  export let subTitle;
  // init chart
  onMount(async () => {
    var happyCS = []
    var happyKebersihan = []
    var goodCS = []
    var goodKebersihan = []
    var neutralCS = []
    var neutralKebersihan = []
    var badCS = []
    var badKebersihan = []
    var angryCS = []
    var angryKebersihan = []
    var happyCSminusOne = []
    var happyKebersihanminusOne = []
    var goodCSminusOne = []
    var goodKebersihanminusOne = []
    var neutralCSminusOne = []
    var neutralKebersihanminusOne = []
    var badCSminusOne = []
    var badKebersihanminusOne = []
    var angryCSminusOne = []
    var angryKebersihanminusOne = []
    var year = new Date().getFullYear()
    var month = new Date().getMonth() + 1 
    var monthminusOne = new Date().getMonth()
    var datajson = await fetch('https://api.liztransport.com/api/loyalty/rentcar/kepuasan/'+year+'/'+month).then(response => response.json())
    var datajsonminusOne = await fetch('https://api.liztransport.com/api/loyalty/rentcar/kepuasan/'+year+'/'+monthminusOne).then(response => response.json())
    for(var k in datajson){
    if(datajson[k]['satisfaction'] == "happy"){
        happyCS.push(datajson[k]);
    }else if (datajson[k]['satisfaction'] == "good"){
        goodCS.push(datajson[k]);
    }
    else if(datajson[k]['satisfaction'] == "ok"){
        neutralCS.push(datajson[k]);
    }
    else if(datajson[k]['satisfaction'] == "sad"){
        badCS.push(datajson[k]);
    }else if (datajson[k]['satisfaction'] == "angry"){
        angryCS.push(datajson[k]);
    }
    if (datajson[k]['kebersihan'] == "happy"){
        happyKebersihan.push(datajson[k]);
    }else if (datajson[k]['kebersihan'] == "good"){
        goodKebersihan.push(datajson[k]);
    }
    else if(datajson[k]['kebersihan'] == "ok"){
        neutralKebersihan.push(datajson[k]);
    }
    else if(datajson[k]['kebersihan'] == "sad"){
        badKebersihan.push(datajson[k]);
    }else if (datajson[k]['kebersihan'] == "angry"){
        angryKebersihan.push(datajson[k]);
    }
  }
  for(var k in datajsonminusOne){
    if(datajsonminusOne[k]['satisfaction'] == "happy"){
        happyCSminusOne.push(datajsonminusOne[k]);
    }else if (datajsonminusOne[k]['satisfaction'] == "good"){
        goodCSminusOne.push(datajsonminusOne[k]);
    }
    else if(datajsonminusOne[k]['satisfaction'] == "ok"){
        neutralCSminusOne.push(datajsonminusOne[k]);
    }
    else if(datajsonminusOne[k]['satisfaction'] == "sad"){
        badCSminusOne.push(datajsonminusOne[k]);
    }else if (datajsonminusOne[k]['satisfaction'] == "angry"){
        angryCSminusOne.push(datajsonminusOne[k]);
    }
    if (datajsonminusOne[k]['kebersihan'] == "happy"){
        happyKebersihanminusOne.push(datajsonminusOne[k]);
    }else if (datajsonminusOne[k]['kebersihan'] == "good"){
        goodKebersihanminusOne.push(datajsonminusOne[k]);
    }
    else if(datajsonminusOne[k]['kebersihan'] == "ok"){
        neutralKebersihanminusOne.push(datajsonminusOne[k]);
    }
    else if(datajsonminusOne[k]['kebersihan'] == "sad"){
        badKebersihanminusOne.push(datajsonminusOne[k]);
    }else if (datajsonminusOne[k]['kebersihan'] == "angry"){
        angryKebersihanminusOne.push(datajsonminusOne[k]);
    }
  }
  

    if(chart == "cs"){
      let config = {
      type: "bar",
      data: {
        labels: [
          "Happy",
          "Good",
          "Neutral",
          "Bad",
          "Angry",
        ],
        datasets: [
          {
            label: "This Month",
            backgroundColor: "#ed64a6",
            borderColor: "#ed64a6",
            data: [
              happyCS.length,
              goodCS.length,
              neutralCS.length,
              badCS.length,
              angryCS.length,
            ],
            fill: true,
            barThickness: 8
          },
          {
            label: "Last Month",
            fill: true,
            backgroundColor: "#4c51bf",
            borderColor: "#4c51bf",
            data: [
              happyCSminusOne.length,
              goodCSminusOne.length,
              neutralCSminusOne.length,
              badCSminusOne.length,
              angryCSminusOne.length,
            ],
            barThickness: 8
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              display: false,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    let ctx = document.getElementById(chart).getContext("2d");
    window.myBar = new Chart(ctx, config);
    }else if(chart=="kebersihan"){
      let config = {
      type: "bar",
      data: {
        labels: [
          "Happy",
          "Good",
          "Neutral",
          "Bad",
          "Angry",
        ],
        datasets: [
          {
            label: "This Month",
            backgroundColor: "#ed64a6",
            borderColor: "#ed64a6",
            data: [
              happyKebersihan.length,
              goodKebersihan.length,
              neutralKebersihan.length,
              badKebersihan.length,
              angryKebersihan.length,              
            ],
            fill: true,
            barThickness: 8
          },
          {
            label: "Last Month",
            fill: true,
            backgroundColor: "#4c51bf",
            borderColor: "#4c51bf",
            data: [
              happyKebersihanminusOne.length,
              goodKebersihanminusOne.length,
              neutralKebersihanminusOne.length,
              badKebersihanminusOne.length,
              angryKebersihanminusOne.length,
             
            ],
            barThickness: 8
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              display: false,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    let ctx = document.getElementById(chart).getContext("2d");
    window.myBar = new Chart(ctx, config);
    }
  });
</script>

<div
  class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
>
  <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full max-w-full flex-grow flex-1">
        <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
          {title}
        </h6>
        <h2 class="text-blueGray-700 text-xl font-semibold">
          {subTitle}
        </h2>
      </div>
    </div>
  </div>
  <div class="p-4 flex-auto">
    <div class="relative h-350-px">
      <canvas id="{chart}"></canvas>
    </div>
  </div>
</div>
