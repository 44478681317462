<script>
  // core components
  import CardLineChart from "components/Cards/CardLineChart.svelte";
  export let location;
</script>

<div>
  <div class="flex flex-wrap">
    <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
      <CardLineChart />
    </div>
  </div>
</div>
