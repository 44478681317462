<script>
  // core components
  import CardTable from "components/Cards/CardTable.svelte";
    import TableSurvey from "../../components/Cards/TableSurvey.svelte";
    import CardStats from "../../components/Cards/CardStats.svelte";
    import CardBarChart from "../../components/Cards/CardBarChart.svelte";
  export let location;
</script>

<div class="flex flex-wrap mt-4">
  <div class="w-full mb-12 px-4">
    <div class="relative md:pt-32 pb-32 pt-12">
      <div class="px-4 md:px-10 mx-auto w-full">
        <div>
          <!-- Card stats -->
          <div class="flex flex-wrap" id="stats">
            <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
              <CardBarChart
              title = "Kepuasan Konsumen"
              subTitle = "CS"
              chart="cs"/>
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <CardBarChart
            title="Kepuasan Konsumen"
            subTitle="Kebersihan"
            chart="kebersihan"/>
        </div>
          </div>
        </div>
      </div>
    </div>
    <TableSurvey />
  </div>
</div>
