<script>
    // core components
    import UserNavbar from "components/Navbars/UserNavbar.svelte";
    import Footer from "components/Footers/Footer.svelte";
    import Penilaian from "components/Penilaian.svelte";

    const team2 = "/assets/img/team-2-800x800.jpg";
    const logo = "/assets/img/logo.png";

    export let location;
    export let surveyId;
    var title = "Luangkan waktu anda 5 detik agar kami lebih baik lagi";
    import {
        onMount
    } from 'svelte';
    onMount(async () => {
        document.querySelectorAll('.feedback li').forEach(entry => entry.addEventListener('click', e => {
            if (!entry.classList.contains('active')) {
                document.querySelector('.feedback li.active').classList.remove('active');
                entry.classList.add('active');
            }
            e.preventDefault();
        }));
        document.querySelectorAll('.feedback1 li').forEach(entry => entry.addEventListener('click', e => {
            if (!entry.classList.contains('active')) {
                document.querySelector('.feedback1 li.active').classList.remove('active');
                entry.classList.add('active');
            }
            e.preventDefault();
        }));

        const res = await fetch('https://api.liztransport.com/api/loyalty/survey/' + surveyId)
        const data = await res.json()
        if(data.status == "success"){
        if (data.data?.status == 1) {
            title = "Terimakasih! Pendapat anda sangat berharga bagi kami";
            //remove formIsian
            document.querySelector('#formIsian').remove();
        }}else{
            title = "Survei tidak ditemukan :(";
            //remove formIsian
            document.querySelector('#formIsian').remove();
        }
    })

    function makeJsonSubmit() {
        var customerService;
        var kebersihan;
        document.querySelectorAll('.feedback li').forEach(entry => {
            if (entry.classList.contains('active')) {
                customerService = entry.classList[0]
            }
        })
        document.querySelectorAll('.feedback1 li').forEach(entry => {
            if (entry.classList.contains('active')) {
                kebersihan = entry.classList[0]
            }
        })
        var textMasukan = document.querySelector("#masukan").value;
        var json = {
            "customerService": customerService,
            "kebersihan": kebersihan,
            "textMasukan": textMasukan,
            "surveyId": surveyId
        }
        fetch('https://api.liztransport.com/api/loyalty/survey/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(json)
            }).then(response => response.json())
            .then(dataResp => {
                if (dataResp.status == "success") {
                    title = "Terimakasih! Pendapat anda sangat berharga bagi kami";
                    //remove formIsian
                    document.querySelector('#formIsian').remove();
                } else {
                    //error
                    alert("Terjadi kesalahan, silahkan coba lagi");                    
                }
            })
    }
</script>

<div>
    <UserNavbar />
    <main class="profile-page">
        <section class="relative block h-500-px">
            <div class="absolute top-0 w-full h-full bg-center bg-cover" style="
            background-color: #438EB9;
          ">
            </div>
            <div class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                style="transform: translateZ(0);">
                <svg class="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
                    <polygon class="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </section>
        <section class="relative py-16 bg-blueGray-200">
            <style>
                .feedback1 {
                    --normal: #eceaf3;
                    --normal-shadow: #d9d8e3;
                    --normal-mouth: #9795a4;
                    --normal-eye: #595861;
                    --active: #f8da69;
                    --active-shadow: #f4b555;
                    --active-mouth: #f05136;
                    --active-eye: #313036;
                    --active-tear: #76b5e7;
                    --active-shadow-angry: #e94f1d;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                }

                .feedback1 li {
                    position: relative;
                    border-radius: 50%;
                    background: var(--sb, var(--normal));
                    box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
                    transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
                    -webkit-tap-highlight-color: transparent;
                }

                .feedback1 li:not(:last-child) {
                    margin-right: 20px;
                }

                .feedback1 li div {
                    width: 40px;
                    height: 40px;
                    position: relative;
                    transform: perspective(240px) translateZ(4px);
                }

                .feedback1 li div svg,
                .feedback1 li div:before,
                .feedback1 li div:after {
                    display: block;
                    position: absolute;
                    left: var(--l, 9px);
                    top: var(--t, 13px);
                    width: var(--w, 8px);
                    height: var(--h, 2px);
                    transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
                }

                .feedback1 li div svg {
                    fill: none;
                    stroke: var(--s);
                    stroke-width: 2px;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    transition: stroke 0.4s;
                }

                .feedback1 li div svg.eye {
                    --s: var(--e, var(--normal-eye));
                    --t: 17px;
                    --w: 7px;
                    --h: 4px;
                }

                .feedback1 li div svg.eye.right {
                    --l: 23px;
                }

                .feedback1 li div svg.mouth {
                    --s: var(--m, var(--normal-mouth));
                    --l: 11px;
                    --t: 23px;
                    --w: 18px;
                    --h: 7px;
                }

                .feedback1 li div:before,
                .feedback1 li div:after {
                    content: '';
                    z-index: var(--zi, 1);
                    border-radius: var(--br, 1px);
                    background: var(--b, var(--e, var(--normal-eye)));
                    transition: background 0.4s;
                }

                .feedback1 li.angry {
                    --step-1-rx: -24deg;
                    --step-1-ry: 20deg;
                    --step-2-rx: -24deg;
                    --step-2-ry: -20deg;
                }

                .feedback1 li.angry div:before {
                    --r: 20deg;
                }

                .feedback1 li.angry div:after {
                    --l: 23px;
                    --r: -20deg;
                }

                .feedback1 li.angry div svg.eye {
                    stroke-dasharray: 4.55;
                    stroke-dashoffset: 8.15;
                }

                .feedback1 li.angry.active {
                    animation: angry 1s linear;
                }

                .feedback1 li.angry.active div:before {
                    --middle-y: -2px;
                    --middle-r: 22deg;
                    animation: toggle 0.8s linear forwards;
                }

                .feedback1 li.angry.active div:after {
                    --middle-y: 1px;
                    --middle-r: -18deg;
                    animation: toggle 0.8s linear forwards;
                }

                .feedback1 li.sad {
                    --step-1-rx: 20deg;
                    --step-1-ry: -12deg;
                    --step-2-rx: -18deg;
                    --step-2-ry: 14deg;
                }

                .feedback1 li.sad div:before,
                .feedback1 li.sad div:after {
                    --b: var(--active-tear);
                    --sc: 0;
                    --w: 5px;
                    --h: 5px;
                    --t: 15px;
                    --br: 50%;
                }

                .feedback1 li.sad div:after {
                    --l: 25px;
                }

                .feedback1 li.sad div svg.eye {
                    --t: 16px;
                }

                .feedback1 li.sad div svg.mouth {
                    --t: 24px;
                    stroke-dasharray: 9.5;
                    stroke-dashoffset: 33.25;
                }

                .feedback1 li.sad.active div:before,
                .feedback1 li.sad.active div:after {
                    animation: tear 0.6s linear forwards;
                }

                .feedback1 li.ok {
                    --step-1-rx: 4deg;
                    --step-1-ry: -22deg;
                    --step-1-rz: 6deg;
                    --step-2-rx: 4deg;
                    --step-2-ry: 22deg;
                    --step-2-rz: -6deg;
                }

                .feedback1 li.ok div:before {
                    --l: 12px;
                    --t: 17px;
                    --h: 4px;
                    --w: 4px;
                    --br: 50%;
                    box-shadow: 12px 0 0 var(--e, var(--normal-eye));
                }

                .feedback1 li.ok div:after {
                    --l: 13px;
                    --t: 26px;
                    --w: 14px;
                    --h: 2px;
                    --br: 1px;
                    --b: var(--m, var(--normal-mouth));
                }

                .feedback1 li.ok.active div:before {
                    --middle-s-y: 0.35;
                    animation: toggle 0.2s linear forwards;
                }

                .feedback1 li.ok.active div:after {
                    --middle-s-x: 0.5;
                    animation: toggle 0.7s linear forwards;
                }

                .feedback1 li.good {
                    --step-1-rx: -14deg;
                    --step-1-rz: 10deg;
                    --step-2-rx: 10deg;
                    --step-2-rz: -8deg;
                }

                .feedback1 li.good div:before {
                    --b: var(--m, var(--normal-mouth));
                    --w: 5px;
                    --h: 5px;
                    --br: 50%;
                    --t: 22px;
                    --zi: 0;
                    opacity: 0.5;
                    box-shadow: 16px 0 0 var(--b);
                    filter: blur(2px);
                }

                .feedback1 li.good div:after {
                    --sc: 0;
                }

                .feedback1 li.good div svg.eye {
                    --t: 15px;
                    --sc: -1;
                    stroke-dasharray: 4.55;
                    stroke-dashoffset: 8.15;
                }

                .feedback1 li.good div svg.mouth {
                    --t: 22px;
                    --sc: -1;
                    stroke-dasharray: 13.3;
                    stroke-dashoffset: 23.75;
                }

                .feedback1 li.good.active div svg.mouth {
                    --middle-y: 1px;
                    --middle-s: -1;
                    animation: toggle 0.8s linear forwards;
                }

                .feedback1 li.happy div {
                    --step-1-rx: 18deg;
                    --step-1-ry: 24deg;
                    --step-2-rx: 18deg;
                    --step-2-ry: -24deg;
                }

                .feedback1 li.happy div:before {
                    --sc: 0;
                }

                .feedback1 li.happy div:after {
                    --b: var(--m, var(--normal-mouth));
                    --l: 11px;
                    --t: 23px;
                    --w: 18px;
                    --h: 8px;
                    --br: 0 0 8px 8px;
                }

                .feedback1 li.happy div svg.eye {
                    --t: 14px;
                    --sc: -1;
                }

                .feedback1 li.happy.active div:after {
                    --middle-s-x: 0.95;
                    --middle-s-y: 0.75;
                    animation: toggle 0.8s linear forwards;
                }

                .feedback1 li:not(.active) {
                    cursor: pointer;
                }

                .feedback1 li:not(.active):active {
                    transform: scale(0.925);
                }

                .feedback1 li.active {
                    --sb: var(--active);
                    --sh: var(--active-shadow);
                    --m: var(--active-mouth);
                    --e: var(--active-eye);
                }

                .feedback1 li.active div {
                    animation: shake 0.8s linear forwards;
                }

                @keyframes shake {
                    30% {
                        transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
                    }

                    60% {
                        transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
                    }

                    100% {
                        transform: perspective(240px) translateZ(4px);
                    }
                }

                @keyframes tear {
                    0% {
                        opacity: 0;
                        transform: translateY(-2px) scale(0) translateZ(0);
                    }

                    50% {
                        transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
                    }

                    20%,
                    80% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                        transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
                    }
                }

                @keyframes toggle {
                    50% {
                        transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
                    }
                }

                @keyframes angry {
                    40% {
                        background: var(--active);
                    }

                    45% {
                        box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
                    }
                }

                .feedback {
                    --normal: #eceaf3;
                    --normal-shadow: #d9d8e3;
                    --normal-mouth: #9795a4;
                    --normal-eye: #595861;
                    --active: #f8da69;
                    --active-shadow: #f4b555;
                    --active-mouth: #f05136;
                    --active-eye: #313036;
                    --active-tear: #76b5e7;
                    --active-shadow-angry: #e94f1d;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                }

                .feedback li {
                    position: relative;
                    border-radius: 50%;
                    background: var(--sb, var(--normal));
                    box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
                    transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
                    -webkit-tap-highlight-color: transparent;
                }

                .feedback li:not(:last-child) {
                    margin-right: 20px;
                }

                .feedback li div {
                    width: 40px;
                    height: 40px;
                    position: relative;
                    transform: perspective(240px) translateZ(4px);
                }

                .feedback li div svg,
                .feedback li div:before,
                .feedback li div:after {
                    display: block;
                    position: absolute;
                    left: var(--l, 9px);
                    top: var(--t, 13px);
                    width: var(--w, 8px);
                    height: var(--h, 2px);
                    transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
                }

                .feedback li div svg {
                    fill: none;
                    stroke: var(--s);
                    stroke-width: 2px;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    transition: stroke 0.4s;
                }

                .feedback li div svg.eye {
                    --s: var(--e, var(--normal-eye));
                    --t: 17px;
                    --w: 7px;
                    --h: 4px;
                }

                .feedback li div svg.eye.right {
                    --l: 23px;
                }

                .feedback li div svg.mouth {
                    --s: var(--m, var(--normal-mouth));
                    --l: 11px;
                    --t: 23px;
                    --w: 18px;
                    --h: 7px;
                }

                .feedback li div:before,
                .feedback li div:after {
                    content: '';
                    z-index: var(--zi, 1);
                    border-radius: var(--br, 1px);
                    background: var(--b, var(--e, var(--normal-eye)));
                    transition: background 0.4s;
                }

                .feedback li.angry {
                    --step-1-rx: -24deg;
                    --step-1-ry: 20deg;
                    --step-2-rx: -24deg;
                    --step-2-ry: -20deg;
                }

                .feedback li.angry div:before {
                    --r: 20deg;
                }

                .feedback li.angry div:after {
                    --l: 23px;
                    --r: -20deg;
                }

                .feedback li.angry div svg.eye {
                    stroke-dasharray: 4.55;
                    stroke-dashoffset: 8.15;
                }

                .feedback li.angry.active {
                    animation: angry 1s linear;
                }

                .feedback li.angry.active div:before {
                    --middle-y: -2px;
                    --middle-r: 22deg;
                    animation: toggle 0.8s linear forwards;
                }

                .feedback li.angry.active div:after {
                    --middle-y: 1px;
                    --middle-r: -18deg;
                    animation: toggle 0.8s linear forwards;
                }

                .feedback li.sad {
                    --step-1-rx: 20deg;
                    --step-1-ry: -12deg;
                    --step-2-rx: -18deg;
                    --step-2-ry: 14deg;
                }

                .feedback li.sad div:before,
                .feedback li.sad div:after {
                    --b: var(--active-tear);
                    --sc: 0;
                    --w: 5px;
                    --h: 5px;
                    --t: 15px;
                    --br: 50%;
                }

                .feedback li.sad div:after {
                    --l: 25px;
                }

                .feedback li.sad div svg.eye {
                    --t: 16px;
                }

                .feedback li.sad div svg.mouth {
                    --t: 24px;
                    stroke-dasharray: 9.5;
                    stroke-dashoffset: 33.25;
                }

                .feedback li.sad.active div:before,
                .feedback li.sad.active div:after {
                    animation: tear 0.6s linear forwards;
                }

                .feedback li.ok {
                    --step-1-rx: 4deg;
                    --step-1-ry: -22deg;
                    --step-1-rz: 6deg;
                    --step-2-rx: 4deg;
                    --step-2-ry: 22deg;
                    --step-2-rz: -6deg;
                }

                .feedback li.ok div:before {
                    --l: 12px;
                    --t: 17px;
                    --h: 4px;
                    --w: 4px;
                    --br: 50%;
                    box-shadow: 12px 0 0 var(--e, var(--normal-eye));
                }

                .feedback li.ok div:after {
                    --l: 13px;
                    --t: 26px;
                    --w: 14px;
                    --h: 2px;
                    --br: 1px;
                    --b: var(--m, var(--normal-mouth));
                }

                .feedback li.ok.active div:before {
                    --middle-s-y: 0.35;
                    animation: toggle 0.2s linear forwards;
                }

                .feedback li.ok.active div:after {
                    --middle-s-x: 0.5;
                    animation: toggle 0.7s linear forwards;
                }

                .feedback li.good {
                    --step-1-rx: -14deg;
                    --step-1-rz: 10deg;
                    --step-2-rx: 10deg;
                    --step-2-rz: -8deg;
                }

                .feedback li.good div:before {
                    --b: var(--m, var(--normal-mouth));
                    --w: 5px;
                    --h: 5px;
                    --br: 50%;
                    --t: 22px;
                    --zi: 0;
                    opacity: 0.5;
                    box-shadow: 16px 0 0 var(--b);
                    filter: blur(2px);
                }

                .feedback li.good div:after {
                    --sc: 0;
                }

                .feedback li.good div svg.eye {
                    --t: 15px;
                    --sc: -1;
                    stroke-dasharray: 4.55;
                    stroke-dashoffset: 8.15;
                }

                .feedback li.good div svg.mouth {
                    --t: 22px;
                    --sc: -1;
                    stroke-dasharray: 13.3;
                    stroke-dashoffset: 23.75;
                }

                .feedback li.good.active div svg.mouth {
                    --middle-y: 1px;
                    --middle-s: -1;
                    animation: toggle 0.8s linear forwards;
                }

                .feedback li.happy div {
                    --step-1-rx: 18deg;
                    --step-1-ry: 24deg;
                    --step-2-rx: 18deg;
                    --step-2-ry: -24deg;
                }

                .feedback li.happy div:before {
                    --sc: 0;
                }

                .feedback li.happy div:after {
                    --b: var(--m, var(--normal-mouth));
                    --l: 11px;
                    --t: 23px;
                    --w: 18px;
                    --h: 8px;
                    --br: 0 0 8px 8px;
                }

                .feedback li.happy div svg.eye {
                    --t: 14px;
                    --sc: -1;
                }

                .feedback li.happy.active div:after {
                    --middle-s-x: 0.95;
                    --middle-s-y: 0.75;
                    animation: toggle 0.8s linear forwards;
                }

                .feedback li:not(.active) {
                    cursor: pointer;
                }

                .feedback li:not(.active):active {
                    transform: scale(0.925);
                }

                .feedback li.active {
                    --sb: var(--active);
                    --sh: var(--active-shadow);
                    --m: var(--active-mouth);
                    --e: var(--active-eye);
                }

                .feedback li.active div {
                    animation: shake 0.8s linear forwards;
                }

                @keyframes shake {
                    30% {
                        transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
                    }

                    60% {
                        transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
                    }

                    100% {
                        transform: perspective(240px) translateZ(4px);
                    }
                }

                @keyframes tear {
                    0% {
                        opacity: 0;
                        transform: translateY(-2px) scale(0) translateZ(0);
                    }

                    50% {
                        transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
                    }

                    20%,
                    80% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 0;
                        transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
                    }
                }

                @keyframes toggle {
                    50% {
                        transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
                    }
                }

                @keyframes angry {
                    40% {
                        background: var(--active);
                    }

                    45% {
                        box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
                    }
                }
            </style>
            <div class="container mx-auto px-4">
                <div
                    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                    <div class="px-6">
                        <div class="flex flex-wrap m-2 justify-left">
                            <span class="text-xl font-bold text-left block tracking-wide text-blueGray-600">
                                {title}
                            </span>
                        </div>
                        <div class="m-8"></div>
                        <div id="formIsian">
                            <div class="flex flex-wrap m-2 justify-left">
                                <span class="text-large font-bold text-left block tracking-wide text-black">
                                    Bagaimana dengan respon dan pelayanan yang diberikan oleh tim customer service kami?
                                </span>
                            </div>
                            <!-- make a customer satisfaction selection using smiley face -->


                            <div class="flex m-2 justify-center">
                                <ul class="feedback">
                                    <li class="angry">
                                        <div>
                                            <svg class="eye left">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="eye right">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="mouth">
                                                <use xlink:href="#mouth">
                                            </svg>
                                        </div>
                                    </li>
                                    <li class="sad">
                                        <div>
                                            <svg class="eye left">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="eye right">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="mouth">
                                                <use xlink:href="#mouth">
                                            </svg>
                                        </div>
                                    </li>
                                    <li class="ok">
                                        <div></div>
                                    </li>
                                    <li class="good active">
                                        <div>
                                            <svg class="eye left">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="eye right">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="mouth">
                                                <use xlink:href="#mouth">
                                            </svg>
                                        </div>
                                    </li>
                                    <li class="happy">
                                        <div>
                                            <svg class="eye left">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="eye right">
                                                <use xlink:href="#eye">
                                            </svg>
                                        </div>
                                    </li>
                                </ul>
                                <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                                        <path
                                            d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1">
                                        </path>
                                    </symbol>
                                    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                                        <path
                                            d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5">
                                        </path>
                                    </symbol>
                                </svg>

                            </div>

                            <div class="flex flex-wrap m-2 justify-left">
                                <span class="text-large font-bold text-left block tracking-wide text-black">
                                    Bagaimana dengan kebersihan dan kenyamanan dari kendaaraan kami?
                                </span>
                            </div>
                            <!-- make a customer satisfaction selection using smiley face -->


                            <div class="flex m-2 justify-center">
                                <ul class="feedback1">
                                    <li class="angry">
                                        <div>
                                            <svg class="eye left">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="eye right">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="mouth">
                                                <use xlink:href="#mouth">
                                            </svg>
                                        </div>
                                    </li>
                                    <li class="sad">
                                        <div>
                                            <svg class="eye left">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="eye right">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="mouth">
                                                <use xlink:href="#mouth">
                                            </svg>
                                        </div>
                                    </li>
                                    <li class="ok">
                                        <div></div>
                                    </li>
                                    <li class="good active">
                                        <div>
                                            <svg class="eye left">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="eye right">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="mouth">
                                                <use xlink:href="#mouth">
                                            </svg>
                                        </div>
                                    </li>
                                    <li class="happy">
                                        <div>
                                            <svg class="eye left">
                                                <use xlink:href="#eye">
                                            </svg>
                                            <svg class="eye right">
                                                <use xlink:href="#eye">
                                            </svg>
                                        </div>
                                    </li>
                                </ul>
                                <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
                                    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                                        <path
                                            d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1">
                                        </path>
                                    </symbol>
                                    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                                        <path
                                            d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5">
                                        </path>
                                    </symbol>
                                </svg>

                            </div>
                            <div class="flex flex-wrap m-2 justify-left">
                                <span class="text-large font-bold text-left block tracking-wide text-black">
                                    Pendapat anda sangat berarti, apa yang bisa kami tingkatkan lagi untuk Kedepannya?
                                    Terima Kasih!
                                </span>

                            </div>
                            <div class="flex flex-wrap m-2 justify-left">
                                <span class="text-large font-bold text-left block tracking-wide text-black">
                                    Kami sangat berterima kasih atas respon anda.
                                </span>
                                <textarea id="masukan" rows="4"
                                    class="w-full h-128 border border-gray-400 rounded-lg p-2"
                                    placeholder="Tulis pendapat anda disini"></textarea>
                            </div>
                            <!-- make submit button in bottom right -->
                            <div class="flex flex-wrap m-2 justify-end">
                                <button
                                    class="bg-red-400 active:bg-red-500 uppercase text-white font-bold hover:shadow-md shadow text-s px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                    type="button" on:click={()=>{
                                    makeJsonSubmit()
                                    }}>
                                    Kirim
                                </button>
                            </div>
                        </div>
        </section>
    </main>
    <Footer/>
</div>