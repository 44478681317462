<script>
    import { token } from "../../stores.js"
    import { link, navigate } from "svelte-routing";

        localStorage.clear();
        token.set(localStorage.getItem('token'));
        navigate('/');
          //refresh
        window.location.reload();
    console.log("ok")
    logout()
</script>