import { writable } from 'svelte/store';

//verify token with server
//if local storage is not null then fetc
if(localStorage.getItem('token') !== null){
fetch('https://api.liztransport.com/api/loyalty/verifyToken', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        token: localStorage.getItem('token'),
    }),
}).then(response => response.json())
.then(dataResp => {
    if (dataResp.status == "success") {
        //don't do anything
    } else {
        //reset localStorage token
        localStorage.removeItem('token');
        //go to login page
        window.location.href = "/";
    }
})
}

export const token = writable(localStorage.getItem('token'));