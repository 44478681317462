<script>
  // core components
  import CardStats from "components/Cards/CardStats.svelte";
  import { onMount } from "svelte";
  
  let dataResp = {
    transaksiCount: 0,
    transaksiChangePercent: 0,
    pendapatan: 0,
    pendapatanChangePercent: 0,
    transaksiHariCount: 0,
    transaksiHariChangePercent: 0,
    pendapatanHari: 0,
    pendapatanHariChangePercent: 0,
  };

  statArrowTransaksi = 'up'
  statPercentColorTransaksi = 'text-emerald-500' 
  statArrowPendapatan = 'up'
  statPercentColorPendapatan = 'text-emerald-500'
  statArrowTransaksiHari = 'up'
  statPercentColorTransaksiHari = 'text-emerald-500'
  statArrowPendapatanHari = 'up'
  statPercentColorPendapatanHari = 'text-emerald-500'


  onMount(async () => {
    const res = await fetch(`https://api.liztransport.com/api/loyalty/rentcar/stats`);
		dataResp = await res.json();
  });
  $: statArrowTransaksi = dataResp.pendapatanChangePercent > 0 ? "up" : "down";
  $: statPercentColorTransaksi = dataResp.pendapatanChangePercent > 0 ? "text-emerald-500" : "text-red-500";
  $: statArrowPendapatan = dataResp.pendapatanChangePercent > 0 ? "up" : "down";
  $: statPercentColorPendapatan = dataResp.pendapatanChangePercent > 0 ? "text-emerald-500" : "text-red-500";
  $: statArrowTransaksiHari = dataResp.pendapatanHariChangePercent > 0 ? "up" : "down";
  $: statPercentColorTransaksiHari = dataResp.pendapatanHariChangePercent > 0 ? "text-emerald-500" : "text-red-500";
  $: statArrowPendapatanHari = dataResp.pendapatanHariChangePercent > 0 ? "up" : "down";
  $: statPercentColorPendapatanHari = dataResp.pendapatanHariChangePercent > 0 ? "text-emerald-500" : "text-red-500";
</script>

<!-- Header -->
<div class="relative bg-red-500 md:pt-32 pb-32 pt-12">
  <div class="px-4 md:px-10 mx-auto w-full">
    <div>
      <!-- Card stats -->
      <div class="flex flex-wrap" id="stats">
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Transaksi"
            statTitle={dataResp.transaksiCount}
            statArrow={statArrowTransaksi}
            statPercent={Math.round(dataResp.transaksiChangePercent * 100) / 100}
            statPercentColor={statPercentColorTransaksi}
            statDescripiron="Dibanding bulan lalu"
            statIconName="far fa-chart-bar"
            statIconColor="bg-red-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Pendapatan"
            statTitle={dataResp.pendapatan}
            statArrow={statArrowPendapatan}
            statPercent={Math.round(dataResp.pendapatanChangePercent * 100) / 100}
            statPercentColor={statPercentColorPendapatan}
            statDescripiron="Dibanding bulan lalu"
            statIconName="fas fa-dollar-sign"
            statIconColor="bg-orange-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Transaksi Hari Ini"
            statTitle={dataResp.transaksiHariCount}
            statArrow={statArrowTransaksiHari}
            statPercent={Math.round(dataResp.transaksiHariChangePercent * 100) / 100}
            statPercentColor={statPercentColorTransaksiHari}
            statDescripiron="Dibanding kemarin"
            statIconName="far fa-chart-bar"
            statIconColor="bg-red-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="Pendapatan Hari Ini"
            statTitle={dataResp.pendapatanHari}
            statArrow={statArrowPendapatanHari}
            statPercent={Math.round(dataResp.pendapatanHariChangePercent * 100) / 100}
            statPercentColor={statPercentColorPendapatanHari}
            statDescripiron="Dibanding kemarin"
            statIconName="fas fa-dollar-sign"
            statIconColor="bg-orange-500"
          />
        </div>
          <!-- <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <CardStats
              statSubtitle="SALES"
              statTitle="924"
              statArrow="down"
              statPercent="1.10"
              statPercentColor="text-orange-500"
              statDescripiron="Since yesterday"
              statIconName="fas fa-users"
              statIconColor="bg-pink-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <CardStats
              statSubtitle="PERFORMANCE"
              statTitle="49,65%"
              statArrow="up"
              statPercent="12"
              statPercentColor="text-emerald-500"
              statDescripiron="Since last month"
              statIconName="fas fa-percent"
              statIconColor="bg-emerald-500"
            />
          </div> -->
      </div>
    </div>
  </div>
</div>
